<template>
  <div style="background-color: #f8f8f9;">
    <Tabs value="1">
      <TabPane label="采购单打印" name="1">
        <div class="main-info">
        <div v-show="errorFlag" style="margin:0 auto;width: 200mm;padding-top:10px;">
          <Alert show-icon style="font-size:12px;">
            若采购单需要调整，可以去配置打印模板信息!
            <Icon type="ios-bulb-outline" slot="icon"></Icon>
          </Alert>
        </div>
        <div class="print-button">
          <Button type="info"  @click="downData()" :loading="down_modal_loading">下载</Button>
          <Button type="primary" class="ml-10" @click="printData()" :loading="print_modal_loading">打印</Button>
        </div>
        <div id="a5-print">
          <div class="header">
            <div style="min-width: 6mm;">
              <img v-show="a5Item.logo" style="height:12mm;" :src="a5Item.logo" alt="">
            </div>
            <div class="fs-22 fw-400 align-center">{{a5Item.company_name}}采购单</div>
            <div style="width:12mm;">
              <img v-show="a5Item.wxCode" style="width:12mm;height:12mm;" :src="a5Item.wxCode" alt="">
            </div>
          </div>
          <div class="column-info">
            <div><strong>NO.{{order.no}}</strong></div>
            <div style="display:flex;justify-content: space-between;">
              <span>供应商：{{ order.clientName }}<span v-show="order.clientContactPhone">({{order.clientContactPhone}})</span> </span>
              <span>日期：{{order.date_info}}</span>
            </div>
            <!--        <div><span>收货地址: {{order.address}}</span></div>-->
          </div>
          <div style="margin-top:5px;">
            <table cellspacing="0" cellpadding="0" border="1">
              <thead>
              <tr>
                <th><span>编号</span></th>
                <th><span>产品</span></th>
                <th><span>数量</span></th>
                <th><span>单价</span></th>
                <th><span>金额</span></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in order.goods_set">
                <td><span>{{item.no}}</span></td>
                <td>
                  <span v-if="item.free_flag==1" class="fs-12">[赠]</span>
                  <span>{{item.name}}</span>
                </td>
                <td><span>{{item.num}}</span>
                </td>
                <td><span>{{item.price}}</span>
                </td>
                <td><span>{{item.totalPrice}}</span>
                </td>
              </tr>
              <tr>
                <td><span></span>
                </td>
                <td><span>合计</span>
                </td>
                <td><span>{{order.totalNum}}</span>
                </td>
                <td><span></span></td>
                <td><span>{{order.totalCount}}</span></td>
              </tr>
              <tr>
                <td colspan="7" style="text-align: right;padding-left:10px;">
                  <span class="fs-12" v-show="order.discount_amount>0">优惠: {{order.discount_amount}}</span>
                  <span class="ml-10 fs-12" v-show="order.logistics_pay>0">物流运费: {{order.logistics_pay}}</span>
                  <span class="ml-10">合计金额: <strong>{{ order.capital_amount }}</strong>（<strong>{{order.totalPay}}</strong>）</span>
                </td>
              </tr>
              <tr>
                <td colspan="7" style="text-align: left;padding-left:10px;">
                  <span>备注：{{order.remark}}</span>
                </td>
              </tr>
              <tr>
                <td colspan="7" style="text-align: left;padding-left:10px;">
                  <span>*尊敬的客户：货物的数量、价格、费用，请当面点清*</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="column-info">
            <div style="display:flex;justify-content: space-between;">
              <span v-show="a5Item.contact_phone">订货电话：{{a5Item.contact_phone}}</span>
              <span>制单人：{{ order.operator_name }}</span>
            </div>
            <div style="display:flex;justify-content: space-between;">
              <span v-show="a5Item.contact_address">订货地址: {{a5Item.contact_address}}</span>
              <span>收货人(签字)：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </div>
            <div style="font-size:10px;">{{a5Item.company_introduce}}</div>
          </div>
        </div>
      </div>
      </TabPane>
      <TabPane label="商品标签打印" name="2">
        <div class="display-flex" style="width:1080px;margin:20px auto;">
          <div style="width:320px;">
            <div class="align-center"><h2>数据输入</h2></div>
            <Form ref="goods_print_form" :model="printItem" :label-width="80" class="mt-10">
              <FormItem label="货号" prop="main_no">
                <Select  v-model="printItem.main_no" placeholder="请选择货号" >
                  <Option v-for="items in order.goods_set" :value="items.main_no">{{ items.main_no }}</Option>
                </Select>
              </FormItem>
              <FormItem label="CPU" prop="cpu">
                <Input v-model="printItem.cpu" placeholder="请填写cpu"></Input>
              </FormItem>
              <FormItem label="内存" prop="memory">
                <Input v-model="printItem.memory" placeholder="请填写内存"></Input>
              </FormItem>
              <FormItem label="硬盘" prop="hardDisk">
                <Input v-model="printItem.hardDisk" placeholder="请填写硬盘"></Input>
              </FormItem>
              <FormItem label="主板" prop="mainBoard">
                <Input v-model="printItem.mainBoard" placeholder="请填写主板"></Input>
              </FormItem>
              <FormItem label="显卡" prop="graphics">
                <Input v-model="printItem.graphics" placeholder="请填写显卡"></Input>
              </FormItem>
              <FormItem label="电源" prop="power">
                <Input v-model="printItem.power" placeholder="请填写电源"></Input>
              </FormItem>
              <FormItem label="散热" prop="cooling">
                <Input v-model="printItem.cooling" placeholder="请填写散热"></Input>
              </FormItem>
              <FormItem label="其他配件" prop="other">
                <Input v-model="printItem.other" placeholder="请填写其他配件"></Input>
              </FormItem>
              <FormItem label="备注" prop="remark">
                <Input v-model="printItem.remark" placeholder="请填写备注"></Input>
              </FormItem>
            </Form>
            <div class="mt-10 align-center">
              <Button  @click="resetGoodsPrintForm()" >重置</Button>
            </div>
          </div>
          <div style="margin-left:40px;">
            <div class="align-center"><h2>打印效果</h2></div>
            <div id="goods-msg" class="mt-10" style="width: 102.5mm;height: 80mm;padding: 10px;background-color:#fff;border-radius:6px;">
              <div class="align-center" v-if="printItem.main_no">货号: {{printItem.main_no}}</div>
              <div v-if="printItem.cpu" class="mt-8">
                <span>CPU:</span><span class="ml-10">{{printItem.cpu}}</span>
              </div>
              <div v-if="printItem.memory" class="mt-8">
                <span>内存:</span><span class="ml-10">{{printItem.memory}}</span>
              </div>
              <div v-if="printItem.hardDisk" class="mt-8">
                <span>硬盘:</span><span class="ml-10">{{printItem.hardDisk}}</span>
              </div>
              <div v-if="printItem.mainBoard" class="mt-8">
                <span>主板:</span><span class="ml-10">{{printItem.mainBoard}}</span>
              </div>
              <div v-if="printItem.graphics" class="mt-8">
                <span>显卡:</span><span class="ml-10">{{printItem.graphics}}</span>
              </div>
              <div v-if="printItem.power" class="mt-8">
                <span>电源:</span><span class="ml-10">{{printItem.power}}</span>
              </div>
              <div v-if="printItem.cooling" class="mt-8">
                <span>散热:</span><span class="ml-10">{{printItem.cooling}}</span>
              </div>
              <div v-if="printItem.other" class="mt-8">
                <span>其他配件:</span><span class="ml-10">{{printItem.other}}</span>
              </div>
              <div v-if="printItem.remark" class="mt-8">
                <span>备注:</span><span class="ml-10">{{printItem.remark}}</span>
              </div>
            </div>
            <div class="mt-10 align-center">
              <Button type="primary" @click="printGoodsData()" :loading="print_modal_loading">打印</Button>
            </div>
          </div>
        </div>

      </TabPane>
    </Tabs>
  </div>

</template>

<script>
import html2pdf from 'html2pdf.js';
import NP from 'number-precision';
import * as helper from '@/utils/helper';
import printJS from 'print-js'


import {getBuyOrderPrintTemplate} from "@/api/setting/orderPrintSetting";


export default {
  name: "a5Print",
  data() {
    return {
      errorFlag: false,
      print_modal_loading: false,
      down_modal_loading: false,
      printItem: {
        cpu:'',
        main_no:'',
        memory:'',
        hardDisk:'',
        mainBoard:'',
        graphics:'',
        cooling:'',
        power:'',
        other:'无',
        remark:'无'
      },
      a5Item: {
        logo: '',
        wxCode: '',
        contact_address: '',
        company_name: '',
        contact_phone: '',
        company_introduce: '',
      },
      order: {
        capital_amount: '',
        clientName: '',
        clientContactPhone: '',
        operator_name: '',
        no: '',
        date_info: '',
        address: '',
        goods_set: [],
        totalNum: 0,
        totalPay: 0,
        discount_amount: 0,
        logistics_pay: 0,
        totalCount: 0,
        remark: '',
      }
    }
  },
  mounted() {
    const {no, printType} = this.$route.query

    switch (printType) {
      case 'buy':
        this.getBuyData(no);
    }
    this.getOrderPrintTemplate();
  },
  methods: {
    resetGoodsPrintForm() {
      this.$refs['goods_print_form'].resetFields();
    },
    getOrderPrintTemplate() {
      getBuyOrderPrintTemplate().then(res => {
        if (res.data.results.length > 0) {
          const content = JSON.parse(res.data.results[0].content);
          this.a5Item.contact_address = content.contact_address;
          this.a5Item.company_name = content.company_name;
          this.a5Item.contact_phone = content.contact_phone;
          this.a5Item.company_introduce = content.company_introduce;

          if (content.logo) {
            // this.a5Item.logo = content.logo;
            this.getBase64Image(content.logo)
                .then(base64Image => {
                  // 成功获取到base64编码
                  this.a5Item.logo = base64Image;
                }).catch(error => {
              // 获取base64编码失败，处理错误信息
            });
          }

          if (content.wx_code) {
            this.getBase64Image(content.wx_code)
                .then(base64Image => {
                  // 成功获取到base64编码
                  this.a5Item.wxCode = base64Image;
                }).catch(error => {
              // 获取base64编码失败，处理错误信息
            });
          }

        } else {
          this.errorFlag = true;
        }
      })
    },

    downData() {
      this.down_modal_loading = true;
      // 获取要转换为PDF的HTML元素
      const element = document.getElementById('a5-print');

      const options = {
        margin: [0, 0, 0, 0],
        image: {type: 'png', quality: 0.98},
        html2canvas: {scale: 4},
        jsPDF: {unit: 'mm', format: 'a5', orientation: 'landscape'},
      };

      // 将HTML转换为PDF
      html2pdf().set(options).from(element).save('采购单'+this.order.no+'.pdf').then(res => {
        this.down_modal_loading = false;
      });
    },
    printGoodsData() {
      if (this.printItem.main_no == '') {
        this.$Message.error("请填写货品信息");
        return;
      }
      this.print_modal_loading = true;
      // 获取要转换为PDF的HTML元素
      const element = document.getElementById('goods-msg');

      const options = {
        margin: [0, 0, 0, 0],
        image: {type: 'jpeg', quality: 0.98},
        html2canvas: {scale: 2},
        jsPDF: {unit: 'mm', format: [102.5, 80] , orientation: 'landscape'},
      };

      // 将HTML转换为PDF
      html2pdf().set(options).from(element).outputPdf('blob').then(blob => {
        printJS(window.URL.createObjectURL(new Blob([blob],{type: 'application/pdf'})));
        setTimeout(() => {this.print_modal_loading = false;}, 3000 );
      });
    },
    printData() {
      this.print_modal_loading = true;
      // 获取要转换为PDF的HTML元素
      const element = document.getElementById('a5-print');

      const options = {
        margin: [0, 0, 0, 0],
        image: {type: 'png', quality: 0.98},
        html2canvas: {scale: 4},
        jsPDF: {unit: 'mm', format: 'a5', orientation: 'landscape'},
      };

      // 将HTML转换为PDF
      html2pdf().set(options).from(element).outputPdf('blob').then(blob => {
        printJS(window.URL.createObjectURL(new Blob([blob],{type: 'application/pdf'})));
        setTimeout(() => {this.print_modal_loading = false;}, 3000 );
      });
    },
    getBuyData(no) {
      this.$axios.get('/api/buy/getBuyOrderById?id=' + no).then((res) => {
        if (res.data.results) {
          const result = res.data.results
          this.order.no = result.buy_no;
          this.order.date_info = result.buy_date.substring(0, 10);
          this.order.totalNum = parseFloat(result.goods_num);
          this.order.totalPay = parseFloat(result.goods_pay);
          this.order.remark = result.remark;
          this.order.discount_amount = parseFloat(result.discount_amount);
          this.order.logistics_pay = parseFloat(result.logistics_pay);
          this.order.totalCount = NP.plus(result.goods_pay, result.discount_amount, result.logistics_pay);
          // this.order.address = result.address;
          this.order.operator_name = result.operator_name;
          this.order.clientName = result.clientInfo.client_name;
          this.order.clientContactPhone = result.clientInfo.contact_phone;
          this.order.capital_amount = helper.amountOfCapital(this.order.totalPay);

          this.order.goods_set = [];
          let total = result.goods_setted.length;
          for (let i=0; i<total; i++) {
            let tmp = {};
            if (i+1 <= result.goods_setted.length) {
              tmp.no = i+1;
              tmp.main_no = result.goods_setted[i].part_no;
              tmp.name = result.goods_setted[i].part_name;
              tmp.num = parseFloat(result.goods_setted[i].buy_num);
              tmp.price = parseFloat(result.goods_setted[i].buy_price);
              tmp.free_flag = result.goods_setted[i].free_flag;
              // tmp.specification = result.goods_setted[i].specification;
              tmp.totalPrice = NP.times(result.goods_setted[i].buy_num, result.goods_setted[i].buy_price);
            }

            this.order.goods_set.push(tmp);
          }
        } else {
          this.$Message.error('订单ID异常，请留意！！！');
        }
      });
    },
    getBase64Image(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = url+"?timeStamp="+new Date();
        img.onload = function () {
          const canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(this, 0, 0);
          let ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase(); // 获取到图片的格式
          const dataURL = canvas.toDataURL("image/" + ext); // 得到base64 编码的 dataURL
          resolve(dataURL);
        };
        img.onerror = function () {
          reject(new Error("图片加载失败"));
        };
      });
    },
  }
}
</script>

<style scoped>
.main-info {

  height: calc(100vh - 50px);
}

.print-button {
  position: absolute;
  right: 80px;
  padding-top: 20px;
}

#a5-print {
  width: 206mm;
  height: 146mm;
  margin: 0 auto;
  background-color: #fff;
  padding: 3mm 2mm 2mm 2mm;
}

#a5-print .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 12mm;
}

#a5-print table {
  border-collapse: collapse; /* 合并边框 */
  width: 100%;
  border-color: #ccc;
  border-width: 0.75pt;
}


#a5-print table td, #a5-print table th {
  height: 24px;
  text-align: center;
}


</style>
